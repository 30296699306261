import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Grommet, Box, Heading, Anchor } from 'grommet';
import Highlight from 'react-highlight'
import { LinkUp } from 'grommet-icons';


import './App.css';


const App = () => (
  <Router>
    <Grommet>
      <Box align="center">
        <Heading color="brand">
            React XSS Test Bed
        </Heading>
        
        <Box direction="row">
          <Box pad="medium"><Link to="/">Home</Link></Box>
          <Box pad="medium"><Link to="/DangerouslySetInnerHTML">DangerouslySetInnerHTML</Link></Box>
          <Box pad="medium"><Link to="/UnsafeUrl">UnsafeUrl</Link></Box>
        </Box>


        <Route exact path="/" component={Home}/>
        <Route path="/DangerouslySetInnerHTML" component={DangerouslySetInnerHTML}/>
        <Route path="/UnsafeUrl" component={UnsafeUrl}/>
      </Box>
    </Grommet>
  </Router>
)

class Home extends Component {
  render() {
    return (
      <Box align="center">
        <h3> Select an XSS type above <LinkUp color='neutral-1' size='medium' /> </h3>

          We use an EvilSource() function to emulate a real world source of evilness.
          <Highlight className='javascript'>
            {'const getEvilSource = () => {'} <br/>
            {'  let hash = document.location.hash;'} <br/>
            {'  return decodeURIComponent(hash.substr(1));'} <br/>
            {'}'} <br/>
          </Highlight>
      </Box>
    );
  }
}


class DangerouslySetInnerHTML extends Component{
  render(){
    return (
      <Box align="center">
        <h3>DangerouslySetInnerHTML</h3>
        <div dangerouslySetInnerHTML={{__html: getEvilSource()}}></div>
          <Highlight>
            {'<p dangerouslySetInnerHTML={{__html: getEvilSource()}}></p>'}
          </Highlight>
        <h5>
          XSS Method - Go To: <Anchor href="/DangerouslySetInnerHTML#<img src=x onerror=alert(1)>" 
            label='<img src=x onerror=alert(1)>'
          />        
        </h5>
      </Box>
    );
  }
}

class UnsafeUrl extends Component{
  render(){
    return (
      <Box align="center">
        <h3>Unsafe URL</h3>
        <a href={getEvilSource()}>Click me!</a>
          <Highlight>
            {'<a href={getEvilSource()}>Click me!</a>'}
          </Highlight>
        <h5>
          XSS Method - Go To: <Anchor href="/UnsafeUrl#javascript:alert(1);" 
            label='javascript:alert(1);' //eslint-disable-line no-script-url
          /> and Click on `Click me!`
        </h5>
      </Box>
    );
  }
}


const getEvilSource = () => {
  let hash = document.location.hash;
  return decodeURIComponent(hash.substr(1));
}

export default App;
